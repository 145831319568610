<template>
  <div
    :class="{
      'ptn-tabs__container': true,
      'ptn-tabs__container-small': isSmallStatus,
    }"
  >
    <PicNavTabs
      v-if="!isSmallStatus"
      v-model="tabIndex"
      :tabs="formatTabs"
      :reverse="locals.GB_cssRight"
      :underLine="catInfo.type === 'search' || hasBgBanner"
      :background-color="tabBgColor"
    />

    <SlideContent
      v-model="tabIndex"
      :list="list"
      :reverse="locals.GB_cssRight"
      :class="contentClass"
    >
      <SlideContentItem
        v-for="(tab, index) in list"
        :key="tab.tabId"
      >
        <template v-if="firstTabStatus === 'SLIDE_EXPAND' ? tabIndex === index : (tabIndex === index || !firstRendering)">
          <WithTabsContainer
            :list="getFormatList(tab, index)"
            :original-list="tab.navs"
            :constant-data="constantData"
            :is-small-status="isSmallStatus"
            :active-value="activeValue"
            :shape="getShapeByTab(tab)"
            :first-rendering="firstRendering"
            :show-next-icon="index < list.length - 1"
            :on-change="onChange"
            :on-done="onDone"
            :language="language"
            :tab-index="tabIndex"
            :first-tab-status="firstTabStatus"
            :set-first-tab-status="setFirstTabStatus"
          />
        </template>
      </SlideContentItem>
    </SlideContent>

    <div
      v-if="list.length && isSmallStatus && catInfo.type !== 'search'"
      class="split-line__small"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'LayoutTabs',
}
</script>

<script setup>
import { ref, watch, inject, computed, getCurrentInstance } from 'vue'

import WithTabsContainer from './slide-expand/WithTabsContainer.vue'
import PicNavTabs from '../PicNavTabs/index.vue'
import SlideContent from 'public/src/pages/components/FilterBar/PicTopNav/components/PicNavTabs/SlideContent.vue'
import SlideContentItem from 'public/src/pages/components/FilterBar/PicTopNav/components/PicNavTabs/SlideContentItem.vue'
import { RESTET_QUERY_PARAMS, FILTER_RESULT_TYPE_MAP } from 'public/src/pages/components/FilterBar/utils/const.js'
import { PIC_TOP_NAV_SHAPE } from 'public/src/pages/components/FilterBar/utils/const'

import { handlePicTopNavParams } from 'public/src/pages/components/FilterBar/PicTopNav/utils/index.js'
import { unrefs } from 'public/src/pages/components/FilterBar/utils/index.js'
import { getTabAnalysisData, getPicTopNavAnalysisData } from 'public/src/pages/components/FilterBar/PicTopNav/utils/analysis.js'
import useToRealPage from 'public/src/pages/components/FilterBar/PicTopNav/utils/toRealPage.js'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import {
  getIsToQuery,
  onChangeByToQuery
} from 'public/src/pages/components/FilterBar/PicTopNav/utils/toQuery.js'
import { useRoute } from 'public/src/pages/components/FilterBar/hooks/useRouter.js'
import { useSearchFilterWords } from 'public/src/pages/components/FilterBar/hooks/useVuex.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const list = inject('list', {})
const locals = inject('locals', {})
const constantData = inject('constantData', {})
const queryParams = inject('queryParams', {})
const isSmallStatus = inject('isSmallStatus', false)
const language = inject('language', {})
const catInfo = inject('catInfo', {})
const tabBgColor = inject('tabBgColor', 'transparent')
const listAbtResult = inject('listAbtResult', {})
const isUseSearch = inject('isUseSearch', false)
const hasBgBanner = inject('hasBgBanner', false)

const route = useRoute()
const searchFilterWords = useSearchFilterWords()
const currentInstance = getCurrentInstance()
const instance = computed(() =>
  Object.assign(currentInstance.proxy, {
    catInfo: catInfo.value,
    isUseSearch: isUseSearch.value,
  })
)

const firstRendering = ref(true)
const activeValue = computed(() => queryParams.value?.pic_nav_id || '')
const tabIndex = ref(0)
const formatTabs = computed(() => {
  return list.value.map((item, index) => ({
    tabId: item.tabId,
    tabName: item.tabName,
    analyticData: getTabAnalysisData(item, index)
  }))

})

const isToQuery = computed(() =>
  getIsToQuery(unrefs({ catInfo, listAbtResult, isUseSearch }))
)

watch(list, () => {
  const { pic_tab_id } = queryParams.value
  const index = list.value.findIndex(tab => tab.tabId == pic_tab_id)
  tabIndex.value = index > -1 ? index : 0
}, { immediate: true })

const getFormatList = (tabItem, tabIndex) => {
  const { navs = [] } = tabItem || {}

  return navs.map((navItem, navIndex) => ({
    value: navItem.nav_id,
    label: navItem.nav_name,
    src: navItem.nav_img,
    lazyImg: locals.value.LAZY_IMG,
    isLazyLoad: tabIndex > 0 || navIndex > 5,
    analysisData: getPicTopNavAnalysisData({ tabItem, tabIndex, navItem, navIndex, catInfo, isUseSearch: isUseSearch.value, })
  }))
}

const contentClass = ref('')
watch(isSmallStatus, () => {
  contentClass.value = ''
  window.requestAnimationFrame(() => {
    contentClass.value = `picTopNav-container__animation`
  })
})

watch(tabIndex, (index) => {
  firstRendering.value = false
  daEventCenter.triggerNotice({
    daId: '2-17-22',
    extraData: {
      index,
      ...(list?.value?.[index] || {}),
    }
  })
})

const onDone = (type) => {
  const maxLimit = list.value.length - 1 > tabIndex.value // 最后一页不翻页
  if (type === 'next' && maxLimit) {
    tabIndex.value += 1
  } else if (type === 'last' && tabIndex.value > 0) {
    tabIndex.value -= 1
  }
}

const getShapeByTab = tab => {
  return tab.is_blank || catInfo.value.type === 'search'
    ? PIC_TOP_NAV_SHAPE.CIRCLE
    : PIC_TOP_NAV_SHAPE.RECT
}
const onToRealPage = useToRealPage({
  list,
  catInfo,
  extraParams: {
    pageName: catInfo.value.pageName,
  }
})

const emits = defineEmits(['change'])
const onChange = (navIndex, isSelect) => {
  firstRendering.value = false
  const isToRealPage = list.value[tabIndex.value]?.is_blank && !isUseSearch.value

  if (isToRealPage) {
    onToRealPage({ tabIndex: tabIndex.value, navIndex })
    return
  }

  const params = handlePicTopNavParams(unrefs({
    list, tabIndex, navIndex, isSelect, businessReport: true,
    extraParams: {
      ...(isUseSearch.value ? {
        nav_to_query: isToQuery.value ? 1 : 0,
      } : {}),
      pic_tab_jump_type: isToRealPage,
      pageName: catInfo.value.pageName,
      isUseSearch: isUseSearch.value,
    } }))

  if (['page_real_class', 'page_select_class'].includes(catInfo.value.pageName)) {
    analysis.clickPicTopNav({
      ...params,
      from_page_cate_id: catInfo.value.from_page_cate_id || '-'
    })
  } else {

    analysis.clickPicTopNav(params)
  }

  if (isToQuery.value) {
    const tabItem = list.value[tabIndex.value]
    const pic_tab_pos = tabIndex.value + 1
    onChangeByToQuery(
      unrefs({
        list,
        route,
        tabIndex,
        navIndex,
        searchFilterWords,
        instance,
        isSelect,
        extraQuery: {
          pic_tab_id: tabItem.tabId,
          pic_tab_type: tabItem.tabType,
          pic_tab_pos: isNaN(pic_tab_pos) ? '' : pic_tab_pos,
        },
        listAbtResult
      })
    )
    return
  }

  emits('change', {
    params: {
      ...RESTET_QUERY_PARAMS,
      ...params
    },
    filterResult: {
      type: FILTER_RESULT_TYPE_MAP.PIC_TOP_NAV,
      label: params?.pic_nav_name || '',
      value: params?.pic_nav_id || ''
    }
  })
}

const firstTabStatus = ref('')
const setFirstTabStatus = (status) => {
  firstTabStatus.value = status
}
</script>

<style lang="less" scoped>
.ptn-tabs {
  &__container {
    background: #fff;

    .split-line {
      height: 10px;
      margin-top: 12px;
      background: #F5F5F5;
    }

    .split-line__small {
      height: 12px;
      background: #fff;
    }
  }
  &__container-small {
    padding: 6px .16rem;
  }
}
</style>
