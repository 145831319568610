import { mapState, mapGetters, mapMutations } from 'vuex'
import { storeModuleName as pl2ModuleName } from 'public/src/pages/product_app/store/modules/product_list_v2/index.js'
import { FILTER_BAR_MAP } from '../utils/const'
import { analysis } from 'public/src/pages/product_list_v2/analytics/filter.js'
import { handleScrollExpand, emitCloseDropdown, emitUpdateDropdownPanelHeight } from 'public/src/pages/components/FilterBar/eventCenter/index.js'
import { setDisabledScroll } from 'public/src/pages/components/FilterBar/utils/disabledScroll'

import FilterBar from 'public/src/pages/product_list_v2/components/FilterBar.vue'
import PicTopNav from 'public/src/pages/components/FilterBar/PicTopNav/index.vue'
import NavBar from 'public/src/pages/components/FilterBar/NavBar/index.vue'
import CloudTags from 'public/src/pages/components/FilterBar/CloudTags/index.vue'
import { sleep } from 'public/src/pages/components/FilterBar/utils/index.js'

import storeMixins from 'public/src/pages/product_list_v2/js/storeMixins.js'

const JOIN_LIST = [
  'page_real_class',
  'page_select_class',
  'page_search',
  'page_real_time_feedback',
  'page_picked',
  'page_goods_group',
  'page_store',
  'page_daily_new',
  'page_selling_point'
]

export default ({
  storeModuleName = pl2ModuleName
} = {}) => ({
  components: {
    PicTopNav,
    NavBar,
    CloudTags,
    OneClickPayTag: () => import(/* webpackChunkName: "OneClickTag" */ 'public/src/pages/components/FilterBar/OneClickPayTag/index.vue'),
    FilterBar: storeMixins(FilterBar, {
      ...mapState(storeModuleName, ['ComponentState', 'PageState']),
      ...mapGetters(storeModuleName, [
        'locals',
        'language',
        'catInfo',
        'sum',
        'picTopNav',
        'sortMap',
        'sortAllList',
        'sortMap2',
        'fhContext',
        'googleContext',
        'listAbtResult',
        'isTopTrendResultPage',
      ]),
    }),
    PolicyBanner2: () => import(/* webpackChunkName: "plv2_policyBanner2" */ 'public/src/pages/product_list_v2/components/PolicyBanner/index.vue'),
    FilterResult: () => import(/* webpackChunkName: "plv2_filterResult" */ 'public/src/pages/components/FilterBar/FilterResult/index.vue'),
  },
  data () {
    return {
      // scrollEl: '', // container.vue中可以传入滚动元素，默认document
      constantData: {},
      FILTER_BAR_MAP,
      cancelScrollExpandCallback: null,
      isShowAgeLimitDialog: false,
    }
  },
  created () {
    // 兼容组件级别使用此方法(组件级别一般从客户端吊起，所以不考虑SSR情况)
    if(!Object.keys(this.locals)?.length && typeof window !== 'undefined') {
      this.resetLocals()
    }
    const {
      LAZY_IMG,
      IS_RW,
      GB_cssRight,
      lang,
      is_include_critical_css,
      LAZY_IMG_SOLID_COLOR = ''
    } = typeof window === 'undefined' ? this.locals || {} : gbCommonInfo

    this.constantData = Object.assign({}, this.constantData, { lang, LAZY_IMG_SOLID_COLOR, LAZY_IMG, IS_RW, GB_cssRight, is_include_critical_css })
  },
  computed: {
    ...mapState('productList_v2', ['ageLimitDialogShow']), // 成人弹窗在productList_v2中
    filterData() {
      return this.ComponentState.FilterBar?.filterData || {}
    },
    filterBar() {
      return this.ComponentState?.FilterBar || {}
    },
    isReflesh() {
      return this.Request?.type === 'refresh'
    },
    queryParams() {
      return this.ComponentState?.queryParams || {}
    },
    closeBottomLeft () {
      return this.ComponentState?.ProductList?.config?.hideGoodsNum ?? true // 默认隐藏
    },
    isNewFilterBar () { 
      const { type, pageName } = this.catInfo
      if (this.apolloConfig?.[type] == 'off') return false
      if (JOIN_LIST.includes(pageName)) return true

      return false
    },
    ...mapState(storeModuleName, ['ageLimitDialogShow', 'oneClickPayGuideDialogShow']),
    ...mapGetters(storeModuleName, [
      'picTopNav',
      'sortMap',
      'sortMap2',
      'sum',
      'sortAllList',
      'apolloConfig',
      'fhContext',
      'googleContext',
    ]),
    isShowPicTopNav () {
      if (this.filterBar?.filterConfig?.hidePicTopNav) return false
      
      return true
    },
    isShowFilterResult () {
      return this.listAbtResult?.ListFilterNull?.p?.ListFilterNull === 'new' && this.PageState?.hasFilt && !['brand', 'pageTrend'].includes(this.catInfo?.search_type)
    }
  },
  provide () {
    return {
      isImgDataTypeDirectionColumnAbtOn: this.isImgDataTypeDirectionColumnAbtOn,
    }
  },
  async beforeRouteLeave (to, from, next) {
    this.isNewFilterBar && this.switchListPage()
    next()
  },
  activated () {
    this.isNewFilterBar && this.addScrollExpand()
  },
  deactivated () {
    this.isNewFilterBar && this.cancelScrollExpand()
  },
  watch: {
    filterData: {
      handler ({ filterAttrs }) {
        const { min_price, max_price } = this.catInfo
        analysis.exposeFilter({ filterAttrs, filterPrices: { min_price, max_price } })
        analysis.exposeCategory()
        analysis.exposeSortEntrance()
        setDisabledScroll()
      }
    },
    Request (newVal, oldVal) { 
      newVal.type !== oldVal.type && setDisabledScroll()
    },
    isReflesh () {
      setDisabledScroll()
    }
  },
  methods: {
    ...mapMutations(storeModuleName, [
      'resetLocals',
      'updateQueryParams'
    ]),
    isImgDataTypeDirectionColumnAbtOn() {
      return this.listAbtResult?.NewListFilterCccx?.p?.NewListFilterCccx === 'A'
    },
    saveCurrAttr (id, type = 'attr') { 
      this.PageState.staticAttrSetId = id
      this.PageState.staticFilterType = type 
    },
    resetSticky () {
      this.$refs.refPicTopNav?.resetSticky()
      this.$refs.refNavBar?.resetSticky()
      this.$refs.refCloudTags?.resetSticky()
      this.$refs.refOneClickPayTag?.resetSticky()
      this.$refs.refPolicyBanner?.resetSticky()
    },
    toStickyByClick () {
      this.$refs.refPicTopNav?.toStickyByClick()
      this.$refs.refNavBar?.toStickyByClick()
      this.$refs.refCloudTags?.toStickyByClick()
    },
    toStickyAfterFilter () {
      this.resetScrollExpand()
      emitUpdateDropdownPanelHeight(300)

      // 当前是否有吸顶的组件【如配置图文吸顶，但是当前不满足图文吸顶，则不触发】
      const isStickyStatus =
        this.$refs?.refPicTopNav?.isStickyStatus
        || this.$refs.refNavBar?.isStickyStatus
        || this.$refs.refCloudTags?.isStickyStatus
      if (!isStickyStatus) return

      const stickyComp = (this.isShowPicTopNav && this.$refs.refPicTopNav?.list?.length && this.$refs.refPicTopNav)
        || this.$refs.refNavBar
        || this.$refs.refCloudTags
      stickyComp?.toStickyAfterFilter()
    },
    resetScrollExpand () { 
      this.$refs.refPicTopNav?.resetScrollExpand()
      this.$refs.refNavBar?.resetScrollExpand()
      this.$refs.refCloudTags?.resetScrollExpand()
      this.$refs.refOneClickPayTag?.resetScrollExpand()
      this.$refs.refPolicyBanner?.resetScrollExpand()
    },
    // 吸顶项中存在一些不通过vue注入的dom，vue diff无法检测到变化，所以需要手动更新
    updateScrollStickyEffectDom () {
      this.$refs.refPicTopNav?.updateVDom()
      this.$refs.refNavBar?.updateVDom()
      this.$refs.refCloudTags?.updateVDom()
      this.$refs.refPicTopNav?.updatePlaceholder()
    },
    updateFilterResult (params) { 
      if (!this.$refs?.refFilterResult?.updateFilterResult || !params?.filterResult) return
      this.$refs.refFilterResult?.updateFilterResult(params.filterResult)
    },
    clearActiveTagId () {
      this.$refs.refCloudTags?.clearActiveTagId()
    },
    // 图文筛选
    onPicTopNavChange (params) { 
      this.toStickyAfterFilter()
      this.updateQuery(params) 
    },
    // navbar 筛选
    onPicNavChange (params) {
      this.toStickyAfterFilter()
      this.updateQuery(params) 
      this.updateFilterResult(params)
      params.type === 'clearAll' && this.clearActiveTagId()
    },
    // 标签 筛选
    onCloudTagsChange (params) {
      this.toStickyAfterFilter()
      this.updateQuery(params)
      this.updateFilterResult(params)
    },
    // 一键购筛选
    onOneClickPayChange (params) {
      this.updateQuery(params)
      this.updateFilterResult(params)
    },
    // filterResult
    onFilterResultChange (params) { 
      this.updateQuery(params) 
      params.type === 'clearAll' && this.clearActiveTagId()
    },
    async initFilterBarStyle (isFilter) { 
      this.isNewFilterBar ? this.initFilterBarStyleNew(isFilter) : this.initFilterBarStyleOld(isFilter)
      this.resetScrollExpand()
      this.addScrollExpand()
    },
    initFilterBarStyleOld (isFilter) {
      this.$refs.FilterBar?.initFilterBarStyle(isFilter)
    },
    async initFilterBarStyleNew ({ isFilter = false } = {}) { 
      if (!isFilter) {
        this.resetSticky()
        this.resetScrollExpand()
        window.scrollTo(0, 1)
        return
      }
      if (!appEventCenter) return 
      
      // 避免goods 分块导致闪屏，设置列表高度，撑开页面
      $('.product-list-v2__list').css('min-height', '1000px')

      appEventCenter.$once('goodsLoadedOnPageList', () => {
        $('.product-list-v2__list').css('min-height', null)
        this.toStickyAfterFilter()
      })
    },
    openSideFilter () {
      this.isNewFilterBar
        ? this.$refs.refNavBar?.openSideFilter()
        : this.$refs.FilterBar?.openSideFilter()
    },
    switchListPage () {
      emitCloseDropdown()
      this.cancelScrollExpand()
    },
    async addScrollExpand () { 
      await sleep(1500)
      if (this.cancelScrollExpandCallback
        || this.catInfo.pageName == 'page_store' // 店铺列表
        || this.catInfo.sortScene == 'TrendLandingPage' // 趋势落地页
      ) return
      this.cancelScrollExpandCallback = handleScrollExpand({ vm: this })
    },
    cancelScrollExpand () {
      this.cancelScrollExpandCallback && this.cancelScrollExpandCallback()
      this.cancelScrollExpandCallback = null
    },
    onAgaLimitDialogChange (isShow) {
      this.isShowAgeLimitDialog = isShow
    }
  },
})
